import React, { useState, useRef, useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import useCheckJsStatus from '/src/hooks/useCheckJsStatus'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const Card = loadable(() => import('/src/components/Cards/Card'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const Image = loadable(() => import('/src/components/Media/Image'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const InfoGrid = ({ backgroundColour, utm }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            infoGrid {
                                fieldGroupName
                                infoGrid {
                                    addTitleArea
                                    title
                                    description
                                    featureItem {
                                        title
                                        price
                                    }
                                    itemRepeater {
                                        title
                                        description
                                        price
                                        image {
                                            id
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(width: 300)
                                                }
                                            }
                                            altText
                                            title
                                        }
                                    }
                                    referencesList {
                                        type
                                        title
                                        url
                                    }
                                    addCta
                                    callToAction {
                                        target
                                        title
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const [options] = useContext(OptionsContext)

    const browserHasJs = useCheckJsStatus()

    const [isExpanded, setToggleExpansion] = useState(false)
    const [setHeight, setHeightState] = useState('0px')

    const content = useRef(null)

    const toggleAccordionItem = () => {
        setToggleExpansion((prev) => !prev)
        setHeightState(isExpanded === true ? '0px' : `${content.current.scrollHeight}px`)
    }

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        const componentData = node.globalBlocks.infoGrid.infoGrid
        const itemRepeater = componentData.itemRepeater
        const referencesList = componentData.referencesList

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'centered',
            title: componentData.title,
            description: componentData.description
        }

        return (
            <Section key={index} variant={backgroundColour} titleArea={titleArea} className={'c-info-grid'}>
                {itemRepeater && (
                    <div className="row c-info-grid__content-row">
                        {itemRepeater.map((node, index) => {
                            return (
                                <div className="col-md-6 col-lg-4 c-info-grid__item" key={index}>
                                    <Card className={'c-info-grid__item-inner'}>
                                        <div className="c-info-grid__item-title">{node.title}</div>

                                        {node.image && (
                                            <Image data={node.image} className={'c-info-grid__item-image'} />
                                        )}

                                        <div>
                                            <div className="c-info-grid__item-description">
                                                {node.description}
                                                <sup>{index + 1}</sup>
                                            </div>
                                            <div className="c-info-grid__item-price">{node.price}</div>
                                        </div>
                                    </Card>
                                </div>
                            )
                        })}

                        <div className="col-lg-4 c-info-grid__item c-info-grid__item--feature">
                            <Card variant={'feature'} className={'c-info-grid__item-inner'}>
                                <div className="c-info-grid__item-title">{componentData.featureItem.title}</div>

                                <StaticImage
                                    src={'../../../../../images/pl-logotype-white.png'}
                                    width={250}
                                    alt={`${options.siteData.title} logo`}
                                    className={'c-info-grid__item-image'}
                                />

                                <div className="c-info-grid__item-description">
                                    <div className="c-info-grid__item-description-inner">
                                        For only{' '}
                                        <span className="c-info-grid__item-price">
                                            {componentData.featureItem.price}
                                        </span>{' '}
                                        per month
                                    </div>
                                    <sup>9</sup>
                                </div>
                            </Card>
                        </div>
                    </div>
                )}

                <CtaContainer align={'centered'} className={'c-info-grid__cta'}>
                    {componentData.addCta && (
                        <ButtonLink data={componentData.callToAction} size={'lg'} icon={'arrow'} utm={utm} />
                    )}

                    {referencesList && (
                        <button
                            className="c-info-grid__references-button"
                            onClick={toggleAccordionItem}
                            onKeyDown={toggleAccordionItem}
                        >
                            <span>See references</span>
                        </button>
                    )}
                </CtaContainer>

                {referencesList && (
                    <div className="c-info-grid__references-container">
                        <ol
                            className="c-info-grid__references"
                            ref={content}
                            style={browserHasJs ? { maxHeight: `${setHeight}` } : {}}
                        >
                            {referencesList.map((node, index) => {
                                return (
                                    <li key={index}>
                                        {node.type === 'link' ? (
                                            <a href={node.url} target="_blank" rel="noreferrer">
                                                {node.title}
                                            </a>
                                        ) : (
                                            node.title
                                        )}
                                    </li>
                                )
                            })}
                        </ol>
                    </div>
                )}
            </Section>
        )
    })
}

InfoGrid.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

InfoGrid.defaultProps = {
    backgroundColour: 'light'
}

export default InfoGrid

export const query = graphql`
    fragment InfoGridForPageBuilder on WpPage_Acfpagebuilder_Layouts_InfoGrid {
        fieldGroupName
        backgroundColour
    }
`
