import { useState, useEffect } from 'react'

const useCheckJsStatus = () => {
    const [htmlTagStatus, setHtmlTagStatus] = useState(false)

    useEffect(() => {
        const htmlTag = document.querySelector('html')

        setTimeout(() => {
            setHtmlTagStatus(htmlTag.classList.contains('has-js'))
        }, 10)
    }, [])

    return htmlTagStatus
}

export default useCheckJsStatus
